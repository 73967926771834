/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { css, jsx } from "@emotion/core";
import Button from "./Button";
import Label from "./Label";

export default function NewFeature({
  userId,
  spotlights,
  featureId,
  hideTemp,
}) {
  const [updateSpotlight, { data, loading }] = useMutation(UPDATE_SPOTLIGHT);

  async function handleUpdateSpotlight() {
    await updateSpotlight({
      variables: {
        id: userId,
        spotlights: { ...spotlights, [featureId]: true },
      },
    });
  }

  return (
    <>
      <div
        css={css`
          position: fixed;
          width: 360px;
          height: fit-content;
          background: white;
          margin: auto;
          border-radius: 5px;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          z-index: 4;
          @media (max-width: 375px) {
            width: 343px;
          }
        `}
      >
        <div
          css={css`
            float: left;
            width: 100%;
            height: 270px;
            img {
              max-width: 100%;
            }
          `}
        >
          <div
            class="lo-emb-vid"
            style={{ position: "relative", paddingBottom: "75%", height: 0 }}
          >
            <iframe
              src="https://www.loom.com/embed/b691214c310e4aa48d940367b67a2c98?hide_owner=true&hide_share=true&hide_title=true"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            />
          </div>
        </div>
        <div
          css={css`
            float: left;
            width: 100%;
            padding: 36px;
            padding-top: 24px;
            box-sizing: border-box;
          `}
        >
          <div
            css={css`
              float: left;
              width: 100%;
              margin-bottom: 12px;
            `}
          >
            <Label>NEW</Label>
          </div>
          <div
            css={css`
              float: left;
              width: 100%;
              margin-bottom: 12px;
              line-height: 24px;
              font-size: 20px;
            `}
          >
            Auto-Scheduling is Here! 🪄✨
          </div>
          <div
            css={css`
              float: left;
              width: 100%;
              margin-bottom: 24px;
              font-size: 14px;
              font-weight: 300;
              line-height: 16.8px;
              a {
                color: #5551ff;
                font-weight: 500;
                text-decoration: none;
              }
              a:hover {
                text-decoration: underline;
              }
            `}
          >
            Building your schedule has never been easier. Soon’s automated
            scheduler allows you to create a fully compliant and optimized
            schedule in seconds.
            <br />
            <a href="http://www.soon.works/product/auto-scheduling" target="_blank">
              Learn more
            </a>
          </div>
          <div
            css={css`
              float: left;
              width: 100%;
            `}
          >
            <Button
              name="Got it"
              theme="purple-border"
              size="large"
              loading={loading}
              customStyle={{ width: "100%" }}
              action={handleUpdateSpotlight}
            />
          </div>
        </div>
      </div>
      <div
        css={css`
          position: fixed;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.2);
          width: 100%;
          height: 100%;
          z-index: 3;
        `}
        onClick={hideTemp}
      />
    </>
  );
}

const UPDATE_SPOTLIGHT = gql`
  mutation($id: ID!, $spotlights: Json) {
    updateUser(id: $id, spotlights: $spotlights) {
      id
      spotlights
    }
  }
`;
