/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useMemo } from "react";
import Moment from "moment-timezone";
import { css, jsx } from "@emotion/core";
import { ModalContainer, ModalHeader } from "../toolBox/MenuModal";
import InputField from "../toolBox/InputField";
import InputFieldSearchable from "../toolBox/InputFieldSearchable";
import Button from "../toolBox/Button";
import EmptyState from "../toolBox/EmptyState";
// import TextArea from "../toolBox/TextArea";
import AvatarChangeable from "../toolBox/AvatarChangeable";
import NotificationSettings from "../notifications/NotificationSettings";
import ProfileCalendars from "../profilePanel/ProfileCalendars";
import { useMutation, gql, useQuery } from "@apollo/client";
import { handleAPIError } from "../../helpers/handleErrors";
import { GET_GOOGLE_STATUS, GET_INTEGRATION_STATUSES } from "../header/Header";
import {
  guessTimeFormat,
  trackEventIntercom,
  updateIntercom,
} from "../../helpers/functions";
import {
  currencyList,
  getCurrencyBasedOnCode,
  getCurrencyBasedOnName,
} from "../../helpers/currencies";

function Dot({ active }) {
  return (
    <span
      css={css`
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: ${active ? "#5551ff" : "#E2E2E2"};
      `}
    />
  );
}

function IconChange({ label, title, description, image, type, id }) {
  const [updateUser] = useMutation(UPDATE_USER);
  const [updateTeam] = useMutation(UPDATE_TEAM);

  function handleSaveImage(url, action) {
    if (action === "upload") {
      if (type === "avatar") {
        updateUser({ variables: { id, avatar: url } })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => handleAPIError(err, {}));
      } else if (type === "icon") {
        updateTeam({ variables: { id, logo: url } })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => handleAPIError(err, {}));
      }
    } else {
      // also remove url from database and delete image from files
      // the uploadimage false somehow doesn't work
      if (type === "avatar") {
        updateUser({ variables: { id, avatar: null } }).catch((err) =>
          handleAPIError(err, {})
        );
      } else if (type === "icon") {
        updateTeam({ variables: { id, logo: null } }).catch((err) =>
          handleAPIError(err, {})
        );
      }
    }
  }

  return (
    <div
      css={css`
        float: left;
        width: 100%;
      `}
    >
      <div
        css={css`
          float: left;
          width: 86px;
          margin-right: 10px;
        `}
      >
        <label
          css={css`
            float: left;
            height: 17px;
            line-height: 17px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.54);
            margin-bottom: 6px;
          `}
        >
          {label}
        </label>
        <AvatarChangeable
          image={image}
          handleImage={handleSaveImage}
          handleMenu={() => {}}
          preset={type === "avatar" ? "avatar" : "teamlogo"}
          size="mediumLarge"
        />
      </div>
      <div
        css={css`
          float: left;
          width: calc(100% - 96px);
        `}
      >
        <InfoBox title={title} description={description} />
      </div>
    </div>
  );
}

function TeamInfo({
  teamName,
  setTeamName,
  teamCurrency,
  setTeamCurrency,
  teamId,
  teamIcon,
}) {
  const currencyOptions = currencyList.map((currency) => {
    return {
      id: currency.id,
      name: currency.name,
      action: () => {
        setTeamCurrency(currency.name);
      },
      selected: teamCurrency === currency.name,
    };
  });

  return (
    <div>
      <InputField
        autoFocus={true}
        value={teamName}
        label="Team name"
        placeholder="Enter your team name"
        customStyle={{}}
        changeInput={(e) => setTeamName(e.target.value)}
        charLimit={45}
        charLimitOnlyWhenExceeded
      />
      <div
        css={css`
          float: left;
          margin-top: 32px;
        `}
      >
        <IconChange
          label="Team icon"
          title="Why add a team icon?"
          description="A team icon helps personalize your team account and makes it easier
            for you and others to identify this team."
          id={teamId}
          image={teamIcon}
          type="icon"
        />
      </div>
      <div
        css={css`
          float: left;
          width: 100%;
          margin-top: 32px;
        `}
      >
        <InputFieldSearchable
          label="Team currency"
          value={teamCurrency}
          handleChange={(e) => setTeamCurrency(e.target.value)}
          options={currencyOptions}
          name="currency"
          onBlur={() => {}}
          emptyText={
            <EmptyState
              title="No results"
              icon="bi_interface-search"
              message="No currencies found matching your search."
              size="small"
              customStyle={{ border: 0 }}
            />
          }
          emptyStyle={{ paddingLeft: 12, paddingRight: 12 }}
          customStyle={{ marginBottom: 24 }}
          placeholder={"Select your team currency"}
          dropdownCustomStyle={{ zIndex: 10 }}
        />
      </div>
    </div>
  );
}

function ProfileInfo({
  firstName,
  lastName,
  timezone,
  timeFormat,
  avatar,
  userId,
  setFirstName,
  setLastName,
  setTimezone,
  setTimeFormat,
  resetTimezone,
}) {
  const tzOptions = Moment.tz.names().map((name) => {
    return {
      id: name,
      name,
      action: () => setTimezone(name),
      selected: name === timezone,
    };
  });
  return (
    <div>
      <div
        css={css`
          float: left;
          width: 100%;
        `}
      >
        <div
          css={css`
            float: left;
            width: calc(50% - 6px);
            margin-right: 12px;
          `}
        >
          <InputField
            autoFocus={true}
            value={firstName}
            label="First name"
            placeholder="Enter your first name"
            customStyle={{}}
            changeInput={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div
          css={css`
            float: left;
            width: calc(50% - 6px);
          `}
        >
          <InputField
            value={lastName}
            label="Last name"
            placeholder="Enter your last name"
            customStyle={{}}
            changeInput={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      <div
        css={css`
          float: left;
          margin-top: 32px;
          margin-bottom: 24px;
        `}
      >
        <IconChange
          label="Profile photo"
          title="Why add a profile photo?"
          description="A photo helps personalize your profile and makes it easier for others to recognize you."
          image={avatar}
          type="avatar"
          id={userId}
        />
      </div>
      <InputField
        label="Time format"
        value={timeFormat === "24-hour" ? "13:00" : "1:00pm"}
        options={[
          {
            id: 1,
            name: "13:00",
            selected: timeFormat === "24-hour",
            action: () => {
              setTimeFormat("24-hour");
            },
          },
          {
            id: 2,
            name: "1:00pm",
            selected: timeFormat === "12-hour",
            action: () => {
              setTimeFormat("12-hour");
            },
          },
        ]}
        customStyle={{ marginBottom: 24 }}
      />
      <InputFieldSearchable
        label="Time zone"
        value={timezone}
        handleChange={(e) => setTimezone(e.target.value)}
        options={tzOptions}
        emptyText={
          <EmptyState
            title="No results"
            icon="bi_interface-search"
            message="No time zones found matching your search."
            size="small"
            customStyle={{ border: 0 }}
          />
        }
        emptyStyle={{ paddingLeft: 12, paddingRight: 12 }}
        customStyle={{ marginBottom: 24 }}
        handleBlur={resetTimezone}
        placeholder="Select your time zone"
      />
    </div>
  );
}

function NotificationPreferences({ userId, emailNotifications }) {
  return (
    <div>
      <NotificationSettings
        emailNotifications={emailNotifications}
        userId={userId}
      />
    </div>
  );
}

function Integrations({ userId, googleCalendarConfig }) {
  const { loading, error, data, refetch } = useQuery(GET_GOOGLE_STATUS, {
    variables: {
      userId,
    },
  });
  const { loading: loadingIntegrationStatuses, data: dataIntegrationStatuses } =
    useQuery(GET_INTEGRATION_STATUSES, {
      variables: {
        userId,
      },
    });

  let googleStatus;
  if (!loading && data) {
    if (!data.getGoogleStatus.error) {
      googleStatus = data.getGoogleStatus;
    }
  }
  let integrationStatuses;
  let noIntegrations = true;
  if (!loadingIntegrationStatuses && dataIntegrationStatuses) {
    integrationStatuses = dataIntegrationStatuses.getIntegrationStatuses;
    if (integrationStatuses?.integrations.length > 0) {
      noIntegrations = false;
    }
  }

  return (
    <div>
      <div
        css={css`
          float: left;
          width: 100%;
          height: 20px;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 6px;
          font-weight: 700;
        `}
      >
        Calendar account
      </div>
      <div
        css={css`
          float: left;
          width: 100%;
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 24px;
        `}
      >
        Automatically sync all your events, activities, and leave requests with
        your calendar account in real-time.
      </div>
      <ProfileCalendars
        userId={userId}
        googleStatus={googleStatus}
        googleCalendarConfig={googleCalendarConfig}
        noInputField={noIntegrations}
        integrationStatuses={integrationStatuses}
      />
    </div>
  );
}

export default function AccountSetup({ user, team, refetch }) {
  const userIs = user.teamClasses[0]?.userIs;
  const storedView = window.localStorage.getItem(`account-setup-${team.id}`);

  let currentView = userIs === "SUPERADMIN" ? "TEAM" : "PROFILE";
  if (storedView) {
    currentView = storedView;
  }

  const [updateUser, { loading: userLoading, error: userError }] =
    useMutation(UPDATE_USER);
  const [
    setSignUpReason,
    { loading: setSignUpReasonLoading, error: setSignUpReasonError },
  ] = useMutation(SET_SIGN_UP_REASON);
  const [updateTeam, { loading: teamLoading, error: teamError }] =
    useMutation(UPDATE_TEAM);
  const [finishAccountSetup, { loading: finishLoading, error: finishError }] =
    useMutation(FINISH_ACCOUNT_SETUP);
  const [view, setView] = useState(currentView);
  const [teamName, setTeamName] = useState(team.name);
  const [teamCurrency, setTeamCurrency] = useState(
    (getCurrencyBasedOnCode(team.currency) &&
      getCurrencyBasedOnCode(team.currency).name) ||
      ""
  );
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [timezone, setTimezone] = useState(Moment.tz.guess());
  const [timeFormat, setTimeFormat] = useState(guessTimeFormat());
  const [challenge, setChallenge] = useState("");

  const potentialQuestions = [
    "What do you want to solve with Soon?",
    "What is the biggest challenge in your current schedule?",
    "What could improve your current schedule?",
  ];
  // pick random question and memoize
  const question = useMemo(() => {
    return potentialQuestions[
      Math.floor(Math.random() * potentialQuestions.length)
    ];
  }, []);

  const error = userError || teamError || finishError;
  const loading =
    userLoading || teamLoading || finishLoading || setSignUpReasonLoading;

  async function handleFinishAccountSetup() {
    const intercomData = {
      name: firstName + " " + lastName,
      first_name: firstName,
      last_name: lastName,
      email: user.email,
      companies: [
        {
          ...team,
          name: teamName,
        },
      ],
      accountSetup: true,
      reason: challenge,
    };
    try {
      updateIntercom({ data: intercomData });
      trackEventIntercom({ name: "account_setup_finished" });
    } catch (e) {
      console.log(e);
    }

    window.localStorage.removeItem(`account-setup-${team.id}`);
    await finishAccountSetup();
    await refetch();
  }

  let content;
  let handleAction;
  let handleBack;
  let title;
  let disabled;
  switch (view) {
    case "TEAM":
      title = "Create your team";
      content = (
        <TeamInfo
          teamName={teamName}
          setTeamName={setTeamName}
          teamCurrency={teamCurrency}
          setTeamCurrency={setTeamCurrency}
          teamId={team.id}
          teamIcon={team.logo}
        />
      );
      handleAction = async () => {
        let trimmedName = teamName.trim();
        if (trimmedName !== teamName) {
          setTeamName(trimmedName);
        }
        if (trimmedName && trimmedName !== team.name) {
          await updateTeam({
            variables: {
              id: team.id,
              name: trimmedName,
              currency: getCurrencyBasedOnName(teamCurrency).id,
            },
          });
        }
        try {
          updateIntercom({
            data: {
              companies: [
                {
                  ...team,
                  name: trimmedName,
                },
              ],
            },
          });
        } catch (e) {
          console.log(e);
        }
        window.localStorage.setItem(`account-setup-${team.id}`, "PROFILE");
        setView("PROFILE");
      };
      disabled =
        !teamName.trim() ||
        teamName.length > 45 ||
        !getCurrencyBasedOnName(teamCurrency);
      break;
    case "PROFILE":
      title = "Configure your profile";
      content = (
        <ProfileInfo
          firstName={firstName}
          lastName={lastName}
          timezone={timezone}
          timeFormat={timeFormat}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setTimezone={setTimezone}
          setTimeFormat={setTimeFormat}
          avatar={user.avatar}
          userId={user.id}
          resetTimezone={() => {
            if (!timezone) {
              setTimezone(user.timezone);
            }
          }}
        />
      );
      handleAction = async () => {
        let trimmedFirstName = firstName.trim();
        if (trimmedFirstName !== firstName) {
          setFirstName(trimmedFirstName);
        }
        let trimmedLastName = lastName.trim();
        if (trimmedLastName !== lastName) {
          setLastName(trimmedLastName);
        }
        await updateUser({
          variables: {
            id: user.id,
            firstName: trimmedFirstName,
            lastName: trimmedLastName,
            timezone,
            timeFormat,
            availabilityTimezone: timezone,
          },
        });
        trackEventIntercom({ name: "is_verified" });
        window.localStorage.setItem(
          `account-setup-${team.id}`,
          "NOTIFICATIONS"
        );
        setView("NOTIFICATIONS");
      };
      handleBack =
        userIs === "SUPERADMIN"
          ? () => {
              window.localStorage.setItem(`account-setup-${team.id}`, "TEAM");
              setView("TEAM");
            }
          : null;
      disabled =
        !firstName.trim() ||
        !lastName.trim() ||
        !timezone ||
        !Moment.tz.names().includes(timezone);
      break;
    case "NOTIFICATIONS":
      title = "Set your notifications";
      content = (
        <NotificationPreferences
          userId={user.id}
          emailNotifications={user.emailNotifications}
        />
      );
      handleAction = () => {
        window.localStorage.setItem(`account-setup-${team.id}`, "INTEGRATIONS");
        setView("INTEGRATIONS");
      };
      handleBack = () => {
        window.localStorage.setItem(`account-setup-${team.id}`, "PROFILE");
        setView("PROFILE");
      };
      break;
    case "INTEGRATIONS":
      title = "Connect your calendar";
      content = (
        <Integrations
          userId={user.id}
          googleCalendarConfig={user.googleCalendarConfig}
        />
      );
      handleAction = () => {
        window.localStorage.setItem(`account-setup-${team.id}`, "CHALLENGE");
        setView("CHALLENGE");
      };
      handleBack = () => {
        window.localStorage.setItem(
          `account-setup-${team.id}`,
          "NOTIFICATIONS"
        );
        setView("NOTIFICATIONS");
      };
      break;
    case "CHALLENGE":
      title = "Tell us your challenge";
      content = (
        <div>
          <div
            css={css`
              float: left;
              width: 100%;
              height: 20px;
              font-size: 14px;
              line-height: 17px;
              margin-bottom: 6px;
              font-weight: 700;
            `}
          >
            Your main challenge
          </div>
          <div
            css={css`
              float: left;
              width: 100%;
              font-size: 14px;
              font-weight: 300;
              margin-bottom: 24px;
            `}
          >
            {question}
          </div>
          <div
            css={css`
              float: left;
              width: 100%;
              margin-bottom: 24px;
              label {
                display: block;
                margin-bottom: 6px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.54);
              }
              textarea {
                width: 100%;
                height: 216px;
                border: 0px;
                background: #f9f9f9;
                border-radius: 3px;
                padding: 12px;
                box-sizing: border-box;
                font-size: 16px;
                font-weight: 300;
                line-height: 20px;
                resize: none;
              }
            `}
          >
            <label>Describe your challenge (required)</label>
            {/* <TextArea
              value={challenge}
              handleChange={(e) => setChallenge(e.target.value)}
              placeholder="Use whichever language you prefer..."
            />{" "} */}
            <textarea
              value={challenge}
              onChange={(e) => setChallenge(e.target.value)}
              placeholder="Use whichever language you prefer..."
            />
          </div>
          <InfoBox
            title="Why do we want to know this?"
            description="Understanding your main challenge helps us tailor your experience with Soon and improve our product. Don’t worry, real humans are reading this — not some bot or algorithm. We’re here to support you and your team!"
          />
        </div>
      );
      const trimmedChallenge = challenge.trim();
      if (!trimmedChallenge || trimmedChallenge.length < 5) {
        disabled = true;
      }
      handleAction = async () => {
        try {
          // set challenge to max 1500 characters
          const reason = challenge.slice(0, 1500);
          // set sign up reason
          await setSignUpReason({ variables: { reason, question } });
        } catch (e) {
          console.log(e);
        }
        await handleFinishAccountSetup();
      };
      handleBack = () => {
        window.localStorage.setItem(`account-setup-${team.id}`, "INTEGRATIONS");
        setView("INTEGRATIONS");
      };
      break;
    default:
      break;
  }

  let buttonName = "Next";
  if (userIs === "SUPERADMIN") {
    if (view === "CHALLENGE") {
      buttonName = "Finish";
    }
  } else {
    if (view === "INTEGRATIONS") {
      buttonName = "Finish";
      handleAction = handleFinishAccountSetup;
    }
  }

  return (
    <div>
      <ModalContainer customStyle={{ position: "fixed", zIndex: 4 }}>
        <ModalHeader
          title={title}
          customStyle={{ background: "#5551FF", color: "white" }}
        />
        <div
          css={css`
            float: left;
            width: 100%;
            padding: 24px 16px;
            height: 510px;
            overflow-y: scroll;
            overflow-x: hidden;
            box-sizing: border-box;
            ::-webkit-scrollbar {
              display: none;
            }
            @media (max-width: 500px) {
              height: calc(100% - 113px);
            }
          `}
        >
          {content}
        </div>
        <div
          css={css`
            position: absolute;
            width: 100%;
            height: 70px;
            bottom: 0;
            border-top: 1px solid #e2e2e2;
            padding-top: 12px;
            padding-left: 16px;
            padding-right: 16px;
            box-sizing: border-box;
            background: white;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          `}
        >
          {handleBack && (
            <Button
              name="Back"
              action={handleBack}
              theme="grey-border"
              customStyle={{ float: "left" }}
            />
          )}
          <div
            css={css`
              position: absolute;
              display: flex;
              gap: 8px;
              height: 48px;
              left: 50%;
              transform: translateX(-50%);
              text-align: center;
              align-items: center;
            `}
          >
            {userIs === "SUPERADMIN" && <Dot active />}{" "}
            <Dot
              active={[
                "PROFILE",
                "NOTIFICATIONS",
                "INTEGRATIONS",
                "CHALLENGE",
              ].includes(view)}
            />{" "}
            <Dot
              active={["NOTIFICATIONS", "INTEGRATIONS", "CHALLENGE"].includes(
                view
              )}
            />{" "}
            <Dot active={["INTEGRATIONS", "CHALLENGE"].includes(view)} />
            {userIs === "SUPERADMIN" && <Dot active={view === "CHALLENGE"} />}
          </div>
          <Button
            name={buttonName}
            action={handleAction}
            theme="purple-border"
            customStyle={{ float: "right" }}
            loading={loading && !error}
            disabled={disabled}
          />
        </div>
      </ModalContainer>
      <div
        css={css`
          position: fixed;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.2);
          z-index: 3;
        `}
      />
    </div>
  );
}

const SET_SIGN_UP_REASON = gql`
  mutation setSignUpReason($reason: String!, $question: String) {
    setSignUpReason(reason: $reason, question: $question)
  }
`;

const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $avatar: String
    $language: String
    $dateFormat: String
    $timeFormat: String
    $timezone: String
    $availabilityTimezone: String
    $firstName: String
    $lastName: String
    $name: String
  ) {
    updateUser(
      id: $id
      avatar: $avatar
      language: $language
      dateFormat: $dateFormat
      timeFormat: $timeFormat
      timezone: $timezone
      availabilityTimezone: $availabilityTimezone
      firstName: $firstName
      lastName: $lastName
      name: $name
    ) {
      id
      firstName
      lastName
      name
      avatar
      language
      dateFormat
      timeFormat
      timezone
      availabilityTimezone
    }
  }
`;

const UPDATE_TEAM = gql`
  mutation updateTeam(
    $id: ID!
    $name: String
    $logo: String
    $currency: String
  ) {
    updateTeam(id: $id, name: $name, logo: $logo, currency: $currency) {
      id
      name
      logo
      currency
    }
  }
`;

const FINISH_ACCOUNT_SETUP = gql`
  mutation finishAccountSetup {
    finishAccountSetup
  }
`;

function InfoBox({ title, description, icon = "i" }) {
  return (
    <div
      css={css`
        float: left;
        width: 100%;
        background: #f9f9f9;
        border-radius: 5px;
        padding: 12px;
        box-sizing: border-box;
      `}
    >
      <div
        css={css`
          float: left;
          width: 16px;
          margin-right: 6px;
        `}
      >
        <div
          css={css`
            float: left;
            width: 16px;
            height: 16px;
            border-radius: 16px;
            background: #e2e2e2;
            font-size: 12px;
            font-weight: 900;
            line-height: 16px;
            text-align: center;
          `}
        >
          {icon}
        </div>
      </div>
      <div
        css={css`
          float: left;
          width: calc(100% - 22px);
          font-size: 14px;
          color: rgba(0, 0, 0, 0.87);
        `}
      >
        <div
          css={css`
            float: left;
            width: 100%;
            height: 17px;
            line-height: 17px;
            margin-bottom: 3px;
          `}
        >
          {title}
        </div>
        <div
          css={css`
            float: left;
            width: 100%;
            font-weight: 300;
            line-height: 17px;
          `}
        >
          {description}
        </div>
      </div>
    </div>
  );
}
